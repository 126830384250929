.portfolioDrop {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(240deg, #1eae98, #d8b5ff);
  background-size: 350% 550%;
  opacity: 0;
  transition: opacity 0.7s;
}

.colorChangeToPortfolio {
  animation: colorChangeToPortfolio 0.7s ease-in-out forwards;
}

#portfolioTextBox {
  height: 75%;
  width: 75%;
  border-radius: 60px;
  border: solid white 2px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  padding: 28px;
  padding-top: 38px;
  word-spacing: 1px;
  font-size: 25px;
  line-height: 30px;
}

#portfolioHeadingLine {
  width: 35%;
  height: 2px;
  background: radial-gradient(white, transparent);
}
