/* id's */

#leftArrow {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 3%;
  top: 48%;
  z-index: 11;
  fill: #ffffff;
  rotate: 270deg;
}

#rightArrow {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 3%;
  top: 48%;
  z-index: 11;
  fill: #ffffff;
  rotate: 90deg;
}

#downIcon {
  height: 30%;
  width: auto;
  fill: white;
}

#downIcon1 {
  height: 30%;
  width: auto;
  fill: white;
}

/* class's */

.clsp-1 {
  fill: #ffffff;
}

.clsp-1,
.clsp-2 {
  stroke-width: 0px;
}

.clsp-3 {
  fill: #ffffff;
  fill-opacity: 0.18;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
}

.clsp-2 {
  fill: #ffffff;
}

.iconSize {
  height: 40%;
  width: 40%;
}

.cls-10,
.cls-20,
.cls-30 {
  fill: #fff;
}

.cls-20 {
  opacity: 0.18;
}

.cls-20,
.cls-30 {
  stroke: #fff;
  stroke-miterlimit: 10;
}

.cls-11,
.cls-21,
.cls-31 {
  fill: #fff;
}

.cls-41 {
  fill: none;
}

.cls-41,
.cls-21,
.cls-31 {
  stroke: #fff;
  stroke-miterlimit: 10;
}

.cls-21 {
  opacity: 0.18;
}

.cls-111,
.cls-222 {
  fill: #fff;
}

.cls-222 {
  opacity: 0.18;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.cls-333 {
  fill: none;
}

.cls-1111,
.cls-2222 {
  fill: #fff;
  stroke-width: 0px;
}

.cls-2222 {
  opacity: 0.18;
}

.clsb-1,
.clsb-2,
.clsb-3 {
  fill: #fff;
}

.clsb-1,
.clsb-3 {
  stroke-width: 0px;
}

.clsb-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.26px;
}

.clsb-3 {
  opacity: 0.18;
}

/* Logo svg's */
.cls-2 {
  fill: #ffffff;
  fill-opacity: 0;
  stroke: #ffffff;
  stroke-width: 0.5px;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: mainLogoSVG 5s ease-in-out, colorSVg 3s ease-in-out;
  animation-fill-mode: forwards;
}

.cls-1 {
  fill: #fff;
  fill-opacity: 0;
  stroke: #fff;
  stroke-width: 0.5px;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: mainLogoSVG 5s ease-in-out, colorSVg 3s ease-in-out;
  animation-fill-mode: forwards;
}
/* */

@media only screen and (max-width: 767px) {
  .iconSize {
    height: 48%;
    width: 48%;
  }
}
