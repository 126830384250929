@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

body {
  margin: 0;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  margin: 0;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

#mediaForPotrait {
  display: none;
}

#wholePage {
  height: 100vh;
  background: linear-gradient(210deg, #93bae8, #edadc9, #b8b5db);
  background-size: 350% 550%;
  position: relative;
}

#allItems1 {
  height: 100vh;
}

#wholePage2 {
  height: 100vh;
  background: linear-gradient(240deg, #1eae98, #d8b5ff);
  background-size: 350% 550%;
  position: relative;
}

.mainDrop {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(210deg, #93bae8, #edadc9, #b8b5db);
  background-size: 350% 550%;
  opacity: 0;
  transition: opacity 0.7s;
}

#wholePage3 {
  background: linear-gradient(210deg, #417177, #2a5470, #4c4177);
  background-size: 150% 150%;
}

&::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6b7685;
}

.scrollContainer {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  -ms-text-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.full-screen-section {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

.full-screen-section2 {
  width: 100%;
  overflow: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

#mainLogoSVG {
  height: 75%;
  width: 75%;
  animation: fadeOutLogo 4s ease-in-out;
  animation-fill-mode: forwards;
}

#menuLogo {
  cursor: pointer;
}

#menuLogo:hover .cls-2 {
  fill-opacity: 0;
  stroke-dasharray: 1500;
  fill: #ffffff;
  stroke: #ffffff;
  animation: mainLogoSVG2 1.5s ease-in-out, colorSVg 2s ease-in-out;
  animation-fill-mode: forwards;
}

#pageElements {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
  transition: opacity 0.7s;
}

.elementHeight {
  height: 100vh;
  position: relative;
}

.scrollHeight {
  height: 10vh;
}

.backdrop {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  display: flex;
  animation: fadeIn 0.5s ease-in-out forwards;
  transition: opacity 0.3s ease;
  overflow: hidden;
}

.backdrop.closing {
  opacity: 0;
}

.sidebarAni {
  z-index: 110;
  animation: sidebarAni 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.sideElementAni {
  transition: 0.2s ease-in-out;
}

.hideSIde {
  animation: hideSide 1s ease-in-out forwards;
}

.underline1 {
  width: 0px;
  height: 3px;
  background-color: #000000;
  z-index: 50;
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

#aboutMeBox {
  height: auto;
  max-height: 80%;
  overflow-y: auto;
  width: 78%;
  border-radius: 60px;
  border: solid white 2px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-sizing: border-box;
  padding: 1.5rem;
  padding-left: 1.2rem;
  font-size: 40px;
  word-spacing: 1px;
  transition: 0.7s;
}

#aboutMeTextInside {
  overflow-y: auto;
}

#aboutMeTextInside::-webkit-scrollbar {
  width: 6px;
}

#lookingForBox::-webkit-scrollbar {
  width: 6px;
}

#skillBox2Text::-webkit-scrollbar {
  width: 6px;
}

#lookingForBox {
  height: auto;
  width: 65%;
  border-radius: 60px;
  border: solid white 2px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  padding: 28px;
  padding-top: 38px;
  word-spacing: 1px;
  font-size: 1.5rem;
  line-height: 30px;
}

#skillTextBox {
  height: 25%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#skillLine1 {
  width: 25%;
  height: 3px;
  background: linear-gradient(90deg, white, transparent);
}

#skillBox {
  height: 75%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 15px;
}

.gridItem {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
  gap: 10px;
}

#skillBox2Top {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#skillBox2Text {
  height: 65%;
  width: 75%;
  border-radius: 45px;
  border: solid white 2px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  padding: 28px;
  padding-top: 2.5rem;
  padding-bottom: 9.5rem;
  word-spacing: 1px;
}

#skillBox2Bottom {
  height: 50%;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#skills2items {
  height: 100%;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

#socialLine {
  width: 50%;
  height: 4px;
  background: radial-gradient(white, transparent);
}

#emailtextSocial {
  font-size: 1.875rem;
}

.fontSizePages {
  font-size: 1.25rem;
  line-height: 30px;
}

#elemetsPage li {
  margin-bottom: 5px;
}

#elemetsPage2 li {
  margin-bottom: 5px;
}

.iconSize2 {
  height: 40%;
  width: 80%;
}

#projectBoxTop {
  height: 90%;
  width: 100%;
  display: flex;
}

#leftBoxTop {
  width: 50%;
  height: 100%;
}

#leftBoxTopUpper {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

#leftBoxTopLower {
  width: 100%;
  height: 10%;
}

#rightBoxTop {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxImage {
  border: 2px solid white;
  width: 85%;
  height: 85%;
  overflow: hidden;
  object-fit: cover;
}

.boxImage img {
  width: 100%; /* Ensures image fits within grid item horizontally */
  height: 100%; /* Ensures image fits within grid item vertically */
  object-fit: cover;
}

#boxForSocial {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media only screen and (max-width: 767px) {
  #sidebar {
    width: 100%;
  }
  #sideBox {
    font-size: 40px;
  }
  #sideBox .btn {
    width: auto !important;
  }
  #sideBox .btn button {
    width: auto !important;
  }
  .sidebarAni {
    z-index: 110;
    animation: sidebarAniPhone 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  #welcomePage {
    text-align: center;
    font-size: 50px;
  }
  #downIcon {
    height: 60%;
  }
  #boxForSocial {
    font-size: 1.65rem;
    line-height: 2.25rem;
  }
  #aboutMeBox {
    max-height: 70%;
    border-radius: 15px;
    width: 88%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 30px;
    word-spacing: 1px;
  }
  #aboutMeTopText {
    justify-content: center;
  }
  #aboutMeTextInside {
    font-size: 1.1rem;
    overflow-y: scroll;
    height: 90%;
  }
  #readMoreButton {
    display: none;
  }
  .scrollButton {
    display: none;
  }
  #lookingForTextTop {
    font-size: 34px;
  }
  #emailtextSocial {
    font-size: 1.5rem;
  }
  #lookingForBox {
    min-height: 40%;
    max-height: 60%;
    height: auto;
    width: 92%;
    border-radius: 35px;
    overflow-y: auto;
    padding: 20px;
    padding-top: 32px;
    padding-bottom: 48px;
    word-spacing: 1px;
    border-radius: 10px;
  }
  #lookingForTextBottom {
    font-size: 1.3rem;
    letter-spacing: 0.3px;
    line-height: 30px;
    padding-bottom: 20px;
  }
  #skillLine1 {
    width: 80%;
  }
  #skillsTextTop1 {
    font-size: 70px;
  }
  #skillBox {
    grid-template-columns: repeat(4, 1fr);
    padding: 0.3rem;
  }
  .gridItem {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 5px;
    text-align: center;
  }
  #skillBox2Top {
    align-items: end;
  }
  #skillBox2Text {
    height: 75%;
    width: 90%;
    font-size: 20px;
    padding-bottom: 2rem;
    border-radius: 20px;
    overflow-y: scroll;
  }
  #skills2items {
    height: 90%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .itm2 {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  #topSocial {
    font-size: 60px;
  }
  #socialLine {
    width: 85%;
  }
  #bottomSocial {
    font-size: 35px;
  }
  #clickToCopyText {
    font-size: 20px;
  }
  #middleSocial {
    align-items: center;
    margin-bottom: 15px;
  }
  #socialMiddleAll {
    gap: 10px;
  }
  #boxForSocial {
    display: grid;
    width: 70%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;
    column-gap: 15px;
  }
  #topSocial {
    height: 25%;
  }
  #thankYouText {
    font-size: 3.1rem;
    text-align: center;
  }
  #pageContent {
    display: none;
  }

  #welcomePagePortfolio {
    font-size: 50px;
  }
  #goDownButton {
    display: none;
  }
  #portfolioTextBox {
    height: 90%;
    width: 90%;
    font-size: 18px;
    overflow-y: scroll;
  }
  #PortTextBox {
    height: 30%;
  }
  #portfolioHeadingBottom {
    font-size: 3.9rem;
  }
  #portfolioHeadingLine {
    width: 100%;
  }
  #textPortfolio {
    font-size: 2.4rem;
  }
  .iconSize2 {
    height: 37%;
    width: 57%;
  }
  #portLetter {
    font-size: 30px;
  }
  #menuLogo {
    height: 80%;
  }
  #logoBox {
    padding: 15px;
    padding-top: 20px;
  }

  #welcomePageProjects {
    font-size: 50px;
  }
  #projectBoxTop {
    flex-direction: column;
  }
  #leftBoxTop {
    width: 100%;
    height: 70%;
  }
  #leftBoxTop p {
    overflow: scroll;
    font-size: 20px;
  }
  #leftBoxTopLower {
    margin-top: 15px;
    text-align: center;
  }
  #rightBoxTop {
    margin-top: 20px;
    width: 100%;
  }
  #fillElement {
    height: 100px;
  }
}

@media only screen and (max-device-height: 480px) and (orientation: landscape) {
  #mediaForPotrait {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: white;
    font-weight: 300;
  }
  #allItems1 {
    display: none;
  }
  #allItems2 {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #sidebar {
    width: 50%;
  }
  .sidebarAni {
    z-index: 110;
    animation: sidebarAniPhone 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  #welcomePage {
    text-align: center;
    font-size: 60px;
  }
  #downIcon {
    height: 60%;
  }
  #aboutMeBox {
    height: 85%;
    width: 85%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 30px;
    word-spacing: 1px;
  }
  #aboutMeTopText {
    justify-content: center;
  }
  #aboutMeTextInside {
    font-size: 20px;
    overflow-y: scroll;
  }
  #readMoreButton {
    display: none;
  }
  .scrollButton {
    display: none;
  }
  #lookingForBox {
    overflow-y: scroll;
  }
  #lookingForTextBottom {
    font-size: 20px;
  }
  #skillLine1 {
    width: 50%;
  }
  #skillsTextTop1 {
    font-size: 70px;
  }
  #skillBox {
    grid-template-columns: repeat(4, 1fr);
    padding: 0px;
  }
  .gridItem {
    width: 100%;
    height: 100%;
    font-size: 25px;
  }
  #skillBox2Top {
    align-items: end;
  }
  #skillBox2Text {
    height: 75%;
    width: 90%;
    font-size: 20px;
    overflow-y: none;
  }
  #skills2items {
    height: 90%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .itm2 {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  #topSocial {
    font-size: 100px;
  }
  #socialLine {
    width: 85%;
  }
  #bottomSocial {
    font-size: 35px;
  }
  #clickToCopyText {
    font-size: 20px;
  }
  #middleSocial {
    align-items: center;
    margin-bottom: 15px;
  }
  #socialMiddleAll {
    gap: 10px;
  }
  #boxForSocial {
    display: grid;
    width: 70%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;
    column-gap: 15px;
  }
  #topSocial {
    height: 25%;
  }
  #thankYouText {
    font-size: 70px;
    text-align: center;
  }
  #pageContent {
    display: none;
  }

  #welcomePagePortfolio {
    font-size: 50px;
  }
  #goDownButton {
    display: none;
  }
  #portfolioTextBox {
    height: 90%;
    width: 80%;
    font-size: 20px;
  }
  #portfolioHeadingBottom {
    font-size: 75px;
  }
  #portfolioHeadingLine {
    width: 80%;
  }
  .iconSize2 {
    height: 40%;
    width: 70%;
  }
  #portLetter {
    font-size: 30px;
  }

  #welcomePageProjects {
    font-size: 50px;
  }
  #projectBoxTop {
    flex-direction: column;
  }
  #leftBoxTop {
    width: 100%;
    height: 70%;
  }
  #leftBoxTop p {
    font-size: 20px;
  }
  #leftBoxTopLower {
    display: none;
  }
  #rightBoxTop {
    width: 100%;
  }
  #fillElement {
    height: 100px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  #aboutMeBox {
    height: 85%;
    width: 85%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 30px;
    word-spacing: 1px;
  }
  #aboutMeTopText {
    justify-content: center;
  }
  #aboutMeTextInside {
    font-size: 20px;
    overflow-y: scroll;
  }
  #readMoreButton {
    display: none;
  }
  .scrollButton {
    display: none;
  }
  #lookingForBox {
    overflow-y: scroll;
  }
  #skillBox2Text {
    height: 75%;
    width: 90%;
    font-size: 20px;
    overflow-y: none;
  }
  #pageContent {
    display: none;
  }

  #welcomePagePortfolio {
    font-size: 50px;
  }
  #goDownButton {
    display: none;
  }
  #portfolioTextBox {
    height: 90%;
    width: 80%;
    font-size: 20px;
  }
  #portfolioHeadingBottom {
    font-size: 75px;
  }
  #portfolioHeadingLine {
    width: 80%;
  }
  .iconSize2 {
    height: 40%;
    width: 70%;
  }
  #portLetter {
    font-size: 30px;
  }
  #leftBoxTop p {
    font-size: 20px;
  }
}
