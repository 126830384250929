.projectsDrop {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(210deg, #417177, #2a5470, #4c4177);
  background-size: 150% 150%;
  opacity: 0;
  transition: opacity 0.7s;
}
